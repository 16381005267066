import { useMemo, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@/theme'
import { TBasicType, EFormFieldType, TPaymentService, TFieldSchema } from '@/models'
import { FieldSwitch } from '@/components/forms/Form/FormFields'
import { usePaymentService, usePaymentServiceSettings } from '@/hooks/usePaymentService'
import { useDebounce } from '@/hooks/useDebounce'
import { useToast } from '@/hooks/useToast'

interface ISettingProps {
  title: string
  description: string
  dataKey: string
  schema?: TFieldSchema
  type: EFormFieldType
  paymentService: TPaymentService
  value: TBasicType | null
  disabled: boolean
  hasState?: boolean
}

export const SettingsSection = (props: { paymentService: TPaymentService }) => {
  const { classes, cx } = useStyles()
  const { settings, provider } = props.paymentService

  const {
    state: { data, updatingSetting, loading },
  } = usePaymentService(provider)

  const entries = Object.entries(settings).map(([key, setting]) => ({
    title: setting.title,
    description:
      typeof setting.description === 'function'
        ? setting.description(data?.paymentOption?.[key])
        : setting.description,
    dataKey: key,
    schema: setting.schema,
    type: setting.type,
    value: props.paymentService[key] || null,
    hasState: setting.hasState,
  }))

  if (loading) return <CircularProgress style={{ alignSelf: 'center' }} />

  return (
    <div className={cx(classes.container)}>
      {entries.map((config, index) => (
        <SettingsEntry
          key={index}
          {...config}
          paymentService={props.paymentService}
          disabled={updatingSetting}
        />
      ))}
    </div>
  )
}

const SettingsEntry = (props: ISettingProps) => {
  const { paymentService, type, dataKey, schema, title, value, description, disabled, hasState } =
    props
  const { classes, cx } = useStyles()
  const toast = useToast()
  const {
    state: { loading },
    actions: { updatePaymentServiceSettings },
  } = usePaymentServiceSettings()

  const [updatedValue, setUpdatedValue] = useState(value)
  const [validationErrors, setValidationErrors] = useState<string[]>([])

  const hasErrors = useMemo(() => validationErrors.length > 0, [validationErrors])

  const isInline = type === EFormFieldType.BOOLEAN

  const onChangeHandler = (newValue: TBasicType) => {
    if (hasState) return handleStateChange(newValue)
    if (type === EFormFieldType.STRING) return debouncedHandleChange(newValue)
    return handleChange(newValue)
  }

  const handleChange = (value: TBasicType) => {
    void updatePaymentServiceSettings(paymentService, {
      [dataKey]: value,
    }).then(({ error }) => {
      if (error) {
        toast.showMessage(`Failed to update ${title}. Details: ${error.errors[0].detail}`, 'error')
      } else {
        toast.showMessage(`${title} updated.`, 'info')
      }
    })
  }
  const debouncedHandleChange = useDebounce(handleChange, 300)

  const handleStateChange = (newValue: TBasicType) => {
    if (schema) {
      schema
        .validate({ [dataKey]: newValue })
        .then(() => {
          setValidationErrors([])
          setUpdatedValue(newValue)
        })
        .catch((e) => {
          setValidationErrors(e.errors)
        })
    } else {
      setUpdatedValue(newValue)
    }
  }

  const onSaveHandler = () => {
    void updatePaymentServiceSettings(paymentService, {
      [dataKey]: updatedValue,
    })
  }

  if (isInline) {
    return (
      <div className={cx(classes.inlineContainer)}>
        <FieldSwitch
          field={{
            label: title,
            name: dataKey,
            type,
          }}
          disabled={disabled}
          value={value}
          onChange={onChangeHandler}
        />
        <div className={cx(classes.inlineFieldContainer)}>
          <Typography variant="subtitle1" color="text.primary">
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            className={cx(classes.inlineDescription)}
          >
            {description}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Typography variant="subtitle1" color="text.primary">
        {title}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" className={cx(classes.description)}>
        {description}
      </Typography>
      <div className={cx(classes.fieldContainer)}>
        <FieldSwitch
          field={{
            label: title,
            name: dataKey,
            type,
          }}
          disabled={disabled}
          value={value}
          onChange={onChangeHandler}
        />
        {hasState && (
          <LoadingButton
            variant="contained"
            onClick={onSaveHandler}
            loading={loading}
            disabled={hasErrors}
          >
            Save
          </LoadingButton>
        )}
      </div>
      {hasErrors && <div className={cx(classes.errors)}>{validationErrors.join(', ')}</div>}
    </div>
  )
}

const useStyles = makeStyles()(() => ({
  inlineContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  inlineFieldContainer: {
    gap: '10px',
  },
  inlineDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    rowGap: '32px',
    columnGap: '32px',
    '& .MuiSwitch-root': {
      transform: 'translate(-10px, 0)',
    },
  },
  description: {
    overflow: 'hidden',
    marginBottom: '8px',
    textOverflow: 'ellipsis',
  },
  fieldContainer: {
    display: 'flex',
    gap: '10px',
  },
  errors: {
    fontSize: '14px',
    color: 'red',
  },
}))
